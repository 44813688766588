import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 72px;
`;

export const TopBar = styled.div`
  max-width: 1300px;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SocialLinks = styled.ul`
  display: flex;
  li {
    list-style: none;
    a {
      font-size: 20px;
      color: ${({ theme }) => theme.primary};
      margin-right: 10px;
    }
  }
`;

export const ButtonsRight = styled.div``;

export const ToggleThemeButton = styled.button`
  background: transparent;
  border: 0;
  font-size: 20px;
  color: ${props => props.theme.primary};
  &:hover {
    cursor: pointer;
  }
`;
export const BackButton = styled.button`
  background: transparent;
  border: 0;
  font-size: 20px;
  color: ${props => props.theme.primary};
  margin-right: 10px;
  &:hover {
    cursor: pointer;
  }
`;
