import styled from 'styled-components';

export const Container = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 72px;
  text-align: center;
  background: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.background};
`;
