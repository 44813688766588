import React, { useContext } from 'react';
import {
  FaLinkedin,
  FaGithub,
  FaTwitter,
  FaInstagram,
  FaSkype,
  FaMedium,
  FaWhatsapp,
  FaRegLightbulb,
  FaArrowLeft,
} from 'react-icons/fa';
import { navigate } from 'gatsby-plugin-intl';

import ThemeContext from '../../styles/themes/context';

import {
  Container,
  TopBar,
  SocialLinks,
  ButtonsRight,
  BackButton,
  ToggleThemeButton,
} from './styles';

export default function Header() {
  const networks = [
    {
      name: 'linkedin',
      icon: <FaLinkedin />,
      url: 'https://www.linkedin.com/in/thiagovpaz',
    },
    {
      name: 'github',
      icon: <FaGithub />,
      url: 'https://github.com/thiagovpaz',
    },
    {
      name: 'twitter',
      icon: <FaTwitter />,
      url: 'https://twitter.com/thiagovpaz',
    },
    {
      name: 'instagram',
      icon: <FaInstagram />,
      url: 'https://instagram.com/thiagovpaz',
    },
    {
      name: 'medium',
      icon: <FaMedium />,
      url: 'https://medium.com/@thiagovpaz',
    },
    {
      name: 'skype',
      icon: <FaSkype />,
      url: 'skype:thiago.vieira.paz?chat',
    },
    {
      name: 'whatsapp',
      icon: <FaWhatsapp />,
      url: 'https://wa.me/8107040867148',
    },
  ];

  const { toggleTheme } = useContext(ThemeContext);

  const handleClick = () => {
    navigate('/');
  };

  const url = typeof window !== 'undefined' ? window.location.pathname : '';

  return (
    <Container>
      <TopBar>
        <SocialLinks>
          {networks.map(social => (
            <li key={social.name}>
              <a href={social.url} target="_blank" rel="noopener noreferrer">
                {social.icon}
              </a>
            </li>
          ))}
        </SocialLinks>
        <ButtonsRight>
          {url.match(/portfolio/g) && (
            <BackButton onClick={handleClick}>
              <FaArrowLeft />
            </BackButton>
          )}

          <ToggleThemeButton onClick={() => toggleTheme()}>
            <FaRegLightbulb />
          </ToggleThemeButton>
        </ButtonsRight>
      </TopBar>
    </Container>
  );
}
